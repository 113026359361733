<template>
  <cw-page
    icon="assignment"
    class="cw-business-loan-terms"
  >
    <template #hero-title>
      {{ heroTitle }}
    </template>
    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row align="start">
        <v-col
          class="
            cw-border-container
            cw-border-container--grey
          "
          cols="12"
          md="6"
        >
          <h3 class="text-subtitle-1 font-weight-bold mb-1">
            <translate>Check the loan summary</translate>
          </h3>
          <p>
            <translate>
              After the loan agreement is signed the granted amount is paid into the merchant’s
              account.
            </translate>
          </p>

          <cw-business-loan-terms-table
            id="cw-business-loan-terms__debtor"
            :items="[ { companyName: initialData.companyName } ]"
            :columns="['companyName']"
            :title="debtorTitle"
            class="mt-6"
          />

          <cw-business-loan-terms-table
            id="cw-business-loan-terms__offer"
            :items="offerItems"
            :columns="['name', 'value']"
            :title="offerTitle"
            class="mt-6"
          />

          <cw-business-loan-terms-table
            id="cw-business-loan-terms__warrantors"
            :items="initialData.warrantors"
            :columns="['name', 'ssn']"
            :title="warrantorsTitle"
            class="mt-6"
          />

          <cw-business-loan-terms-table
            v-if="signees"
            id="cw-business-loan-terms__signees"
            :items="signees"
            :columns="['name', 'ssn']"
            :title="signeesTitle"
            class="mt-6"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <div
            class="
              cw-border-container
              cw-border-container--primary
              cw-border-container--md-center
              mt-6
              mt-md-0
            "
          >
            <h3 class="text-subtitle-1 font-weight-bold mb-1">
              <translate>
                Accept the terms and conditions of the loan
              </translate>
            </h3>
            <p>
              <translate>
                The final loan terms concerning the contract will be sent to the email address
                specified in the loan application.
              </translate>
            </p>

            <cw-business-loan-terms-terms class="mt-8"/>
          </div>

          <div
            class="
              cw-border-container
              cw-border-container--secondary
              cw-border-container--md-center
              mt-6
            "
          >
            <h3 class="text-subtitle-1 font-weight-bold mb-1">
              <translate>
                Contact person
              </translate>
            </h3>
            <p>
              <translate>
                The contact person is responsible for eg. updating your business contact
                information.
              </translate>
            </p>

            <cw-business-loan-terms-checkbox
              id="applicantIsLoanManager"
              :form="form"
              rules="required"
            >
              <template #label>
                <span v-if="isWarrantor" data-test>
                  <translate>
                    I agree to be the contact person for the agreement
                  </translate>
                </span>
                <span
                  v-else
                  v-translate="{
                    openingTag: '<b>',
                    closingTag: '</b>',
                    warrantorText,
                  }"
                  render-html="true"
                  data-test
                >
                  I agree that %{ openingTag }%{ warrantorText }%{ closingTag } is authorized to
                  act as contact person for the agreement.
                </span>
              </template>
            </cw-business-loan-terms-checkbox>
          </div>

          <h3 class="text-subtitle-1 font-weight-bold mt-6 mb-1">
            <translate>
              Political influence
            </translate>
          </h3>
          <p class="ma-0">
            <translate>
              A politically influential exposed person (PEP) is a person who has held public office
              in the past year.
            </translate>
          </p>
          <a
            id="cw-business-loan-terms__PEP-info"
            @click.stop="
              $bus.$emit('OPEN_CONFIRMATION_DIALOG', { component: 'DialogPEP' });
              $eventLogger.clickEvent($event);
            "
          >
            <translate>
              More information
            </translate>
          </a>

          <cw-business-loan-terms-checkbox
            id="politicallyExposedPerson"
            :form="form"
          >
            <template #label>
              <span v-translate data-test>
                I am a politically influential person
              </span>
            </template>
          </cw-business-loan-terms-checkbox>
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        v-if="hasOffers"
        id="returnToOffers"
        class="mr-4"
        text
        @click="
          returnToOffers();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Return to offers
        </translate>
      </v-btn>

      <v-btn
        id="submit"
        :dark="!$wait.waiting(['SUBMIT_FORM', 'LIST_TERMS'])"
        :disabled="$wait.waiting(['SUBMIT_FORM', 'LIST_TERMS'])"
        :loading="$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Sign
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwBusinessLoanTerms',

  components: {
    'cw-business-loan-terms-table': () => import('@fi/pages/steps/businessLoan/BusinessLoanTermsTable'),
    'cw-business-loan-terms-terms': () => import('@fi/pages/steps/businessLoan/BusinessLoanTermsTerms'),
    'cw-business-loan-terms-checkbox': () => import('@fi/pages/steps/businessLoan/BusinessLoanTermsCheckbox'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    form: {
      applicantIsLoanManager: 'off',
      politicallyExposedPerson: 'off',
    },
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      locale: 'application/locale',
      offer: 'application/getSelectedOffer',
      offers: 'application/getLoanOffers',
      terms: 'application/getTerms',
      signees: 'application/signees',
    }),

    debtorTitle: vm => vm.$gettext('The credit applicant'),
    offerTitle: vm => vm.$gettext('Loan offer'),
    warrantorsTitle: vm => vm.$gettext('Guarantors'),
    signeesTitle: vm => vm.$gettext('Signees'),

    hasOffers() {
      return this.offers.length > 1;
    },

    heroTitle() {
      return this.isWarrantor
        ? this.$gettext('Approval of loan agreement')
        : this.$gettext('Signing of loan agreement');
    },

    isWarrantor() {
      return this.initialData.isWarrantor === 'on';
    },

    offerItems() {
      const items = [];
      const { $filters: { currency, percent }, offer } = this;

      if (!offer.id || !offer.instalmentTotals) return [];

      [{
        item: offer.loanLimit,
        name: this.$gettext('Loan amount'),
        formatter: value => currency(value, { locale: this.locale }),
      }, {
        item: offer.numberOfInstalments,
        name: this.$gettext('The loan period'),
        formatter: value => this.$gettextInterpolate(this.$ngettext('%{value} mo', '%{value} mos'), {
          value,
        }),
      }, {
        item: offer.instalmentAmount,
        name: this.$gettext('Monthly Instalment'),
        formatter: value => currency(value, { locale: this.locale }),
      }, {
        item: offer.interestRate,
        name: this.$gettext('Interest'),
        formatter: value => percent(value, { locale: this.locale }),
      }, {
        item: offer.deliveryFeeTotal,
        name: this.$gettext('Opening fee'),
        formatter: value => currency(value, { locale: this.locale }),
      }, {
        item: offer.instalmentTotals.fees,
        name: this.$gettext('Other expenses'),
        formatter: value => currency(value, { locale: this.locale }),
      }, {
        item: offer.instalmentTotals.instalmentAmount,
        name: this.$gettext('Amount due'),
        formatter: value => currency(value, { locale: this.locale }),
      }].forEach(({
        item,
        name,
        formatter,
      }) => items.push({
        name,
        value: formatter(item),
      }));

      return items;
    },

    warrantorText() {
      const { warrantors } = this.initialData;

      if (!warrantors || !warrantors.length) return '';

      const [{ name, ssn }] = warrantors;

      return `${name} (${ssn})`;
    },
  },

  async mounted() {
    await this.getSigningRights();
    await this.setPurchase(true);
  },

  methods: {
    ...mapActions({
      acceptTerms: 'application/acceptTerms',
      getSigningRights: 'application/getSigningRights',
      submitForm: 'application/submit',
      setPurchase: 'application/setPurchase',
    }),

    async returnToOffers() {
      const params = {
        routineParams: {
          uri: 'executeRoutine',
          routine: 'Setup Loan Offer Selection',
        },
      };

      await this.submitForm(params);
    },

    async submit() {
      const {
        $wait,
        form,
        submitForm,
      } = this;

      try {
        $wait.start('SUBMIT_FORM');

        await Promise.all(
          this.terms
            .filter(term => term.value === 'on')
            .map(term => this.acceptTerms({
              termsName: term.name,
            })),
        );

        const params = {
          formParams: form,
          routineParams: {
            uri: 'executeRoutine',
            routine: 'Submit Instalment Loan Terms',
          },
        };

        await submitForm(params);
      } catch (e) {
        $wait.end('SUBMIT_FORM');
      }
    },

    async validateForm() {
      const { $refs, submit } = this;
      const valid = await $refs.observer.validate();
      this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationClick' } });

      DataLayer.onValidationClick();

      if (!valid) {
        this.goToError();
        DataLayer.onValidationFail();

        return;
      }

      this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationSubmit' } });
      await submit();
    },
  },
};
</script>
